import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import ReactMarkdown from 'react-markdown';
import Avatar from '../images/Avatar.svg'; // Importing the Avatar SVG
import '../styles/global.css'; // Ensure the path is correct


const ChatWindow = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [currentStep, setCurrentStep] = useState('initialGreeting');
  const [userInfo, setUserInfo] = useState({});
  const [campaign, setCampaign] = useState(null);
  const [typing, setTyping] = useState(false); // Typing indicator state
  const messagesEndRef = useRef(null);
  const initialGreetingSent = useRef(false);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  // Memoized handleInitialGreeting using useCallback
  const handleInitialGreeting = useCallback(async (campaignParam) => {
    try {
      const response = await axios.post(`${API_URL}/chat`, {
        message: 'initial greeting',
        userInfo: userInfo,
        currentStep: 'initialGreeting',
        conversationHistory: [],
        campaign: campaignParam
      });

      const { botResponse, nextStep, updatedUserInfo } = response.data;
      if (botResponse) {
        setMessages([{ text: botResponse, sender: 'bot' }]);
        setCurrentStep(nextStep);
        setUserInfo(prevInfo => ({ ...prevInfo, ...updatedUserInfo }));
      }
    } catch (error) {
      console.error('Error sending initial greeting:', error);
      setMessages([{ text: "We are offline. Come back later", sender: 'bot' }]);
    }
  }, [userInfo]);

  // useEffect to trigger the initial greeting on mount
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const campaignParam = urlParams.get('campaign');
    if (campaignParam) {
      setCampaign(campaignParam);
    }

    if (!initialGreetingSent.current) {
      handleInitialGreeting(campaignParam);
      initialGreetingSent.current = true;
    }
  }, [handleInitialGreeting]);

  // Handle viewport height change for mobile devices
  useEffect(() => {
    const handleResize = () => setViewportHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  const addMessage = (text, sender) => {
    setMessages(prevMessages => [...prevMessages, { text, sender }]);
  };

  const handleUserInput = async () => {
    if (input.trim() === '') return;

    addMessage(input, 'user');
    setInput('');
    setTyping(true); // Show typing indicator

    try {
      const response = await axios.post(`${API_URL}/chat`, {
        message: input,
        userInfo: userInfo,
        currentStep: currentStep,
        conversationHistory: messages,
        campaign: campaign
      });

      const { botResponse, updatedUserInfo, nextStep } = response.data;

      setTimeout(() => {
        if (botResponse) {
          addMessage(botResponse, 'bot');
        } else {
          addMessage("I'm sorry, I couldn't generate a response. Could you please rephrase your question?", 'bot');
        }
        setUserInfo(prevInfo => ({ ...prevInfo, ...updatedUserInfo }));
        setCurrentStep(nextStep);
        setTyping(false); // Hide typing indicator
      }, 1500); // Simulate typing delay

    } catch (error) {
      console.error('Error sending message:', error);
      addMessage("I'm sorry, I'm having trouble processing your request right now. Please try again later.", 'bot');
      setTyping(false); // Hide typing indicator
    }
  };

  const formatMessage = (text) => {
    return (
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => <p style={styles.paragraph} {...props} />,
          h1: ({ node, ...props }) => <h1 style={styles.heading1} {...props}>{props.children || 'Heading 1'}</h1>,
          h2: ({ node, ...props }) => <h2 style={styles.heading2} {...props}>{props.children || 'Heading 2'}</h2>,
          h3: ({ node, ...props }) => <h3 style={styles.heading3} {...props}>{props.children || 'Heading 3'}</h3>,
          a: ({ node, ...props }) => <a style={styles.link} target="_blank" rel="noopener noreferrer" {...props}>{props.children || 'Link'}</a>,
        }}
      >
        {text}
      </ReactMarkdown>
    );
  };

  return (
    <div style={{ ...styles.chatContainer, height: `${viewportHeight}px` }}>
      <div style={styles.messagesContainer}>
        {messages.map((message, index) => (
          <div key={index} style={message.sender === 'bot' ? styles.botMessage : styles.userMessage}>
            <div style={message.sender === 'bot' ? styles.botMessageContent : styles.userMessageContent}>
              <div style={styles.messageHeader}>
                {message.sender === 'bot' && (
                  <>
                    {/* Add Avatar beside Karolina for bot messages */}
                    <img src={Avatar} alt="Karolina Avatar" style={styles.avatarImage} />
                    <span style={styles.botName}>Karolina</span>
                  </>
                )}
                {message.sender !== 'bot' && (
                  <span style={styles.userName}>Me</span>
                )}
                <span style={styles.messageTime}>
                  Today at {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </span>
              </div>
              <div style={styles.messageText}>
                {formatMessage(message.text)}
              </div>
            </div>
          </div>
        ))}

        {typing && (
          <div style={styles.botMessage}>
              <div style={styles.botMessageContent}>
                <div style={styles.messageHeader}>
                    <img src={Avatar} alt="Karolina Avatar" style={styles.avatarImage} />
                    <span style={styles.botName}>Karolina</span>
                    <span style={styles.messageTime}>
                      Today at {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </span>
                </div>
                <div className="typingIndicator">
                    <div className="typingIndicatorBubbleDot"></div>
                    <div className="typingIndicatorBubbleDot"></div>
                    <div className="typingIndicatorBubbleDot"></div>
                </div>
              </div>
          </div>
        )}


        <div ref={messagesEndRef} />
      </div>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleUserInput()}
          style={styles.input}
          placeholder="Type your message..."
        />
        <button onClick={handleUserInput} style={styles.sendButton}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 2L11 13" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22 2L15 22L11 13L2 9L22 2Z" stroke="#808080" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

const styles = {
  chatContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    backgroundColor: '#FFFFFF',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: '32px',
    paddingBottom: '100px', // Add extra bottom padding to prevent overlap
  },
  botMessage: {
    display: 'flex',
    marginBottom: '24px',
  },
  userMessage: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginBottom: '24px',
  },
  botMessageContent: {
    backgroundColor: '#F6F6F6',
    borderRadius: '0 24px 24px 24px',
    padding: '16px',
    maxWidth: '80%',
  },
  userMessageContent: {
    backgroundColor: '#E0E7F7',
    borderRadius    : '24px 0 24px 24px',
    padding: '16px 24px',
    maxWidth: '80%',
  },
  messageHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '18px',
  },
  botName: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#000000',
    marginRight: '14px', // Add space between the name and date
    fontFamily: 'Raleway, sans-serif',
    lineHeight: '12px',
  },
  userName: {
    fontSize: '20px',
    fontWeight: '600',
    color: '#175EFF',
    marginRight: '14px',
    fontFamily: 'Raleway, sans-serif',
    lineHeight: '12px',
  },
  messageTime: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    fontFamily: 'Raleway, sans-serif',
    lineHeight: '8.68px',
  },
  messageText: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    fontFamily: 'Raleway, sans-serif',
    lineHeight: '20px',
  },
  inputContainer: {
    position: 'sticky', // Use sticky to keep it in view
    bottom: 0,
    display: 'flex',
    padding: '16px',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    borderTop: '1px solid #E0E0E0',
  },
  input: {
    flex: 1,
    padding: '14px 20px',
    borderRadius: '10px',
    background: '#F6F6F6',
    border: '0px',
    fontSize: '16px',
    fontFamily: 'Raleway, sans-serif',
    marginRight: '10px',
  },
  sendButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  avatarImage: {
    width: '35px',
    height: '35px',
    marginRight: '8px',
    verticalAlign: 'middle', // Align the image with the text
  },
  typingIndicator: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '10px',
  },
  typingIndicatorBubbleDot: {
    width: '8px',
    height: '8px',
    marginRight: '6px',
    backgroundColor: '#57585a',
    borderRadius: '50%',
    display: 'inline-block',
    animationName: 'bounce',
    animationDuration: '1.2s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
};

export default ChatWindow;

